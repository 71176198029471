<template>
  <drawer-layout
    :title="isNew ? '新建' : curterm.termsName"
    :show="show"
    :isForm="isNew"
    permission="production:termtemplate:button:edit"
    @edit="handleEdit"
    @closed="closed"
    @save="handleSave"
  >
    <div class="info-group">
      <g-title>基本信息</g-title>
      <info-item :labelWidth="labelWidth" label="术语名" v-if="false">{{ curterm.termsName }}</info-item>
      <info-item :labelWidth="labelWidth" label="分类">{{
        curterm.termsTemplate ? curterm.termsTemplate.templateName : ''
      }}</info-item>

      <g-title>正文</g-title>
      <div v-for="item in curterm.fields" :key="item.id">
        <info-item :labelWidth="labelWidth" :label="item.fieldName" v-if="item.fieldClassId === 2">
          <img :src="item.fieldValue" class="upload_img" alt="avatar" />
        </info-item>
        <info-item :labelWidth="labelWidth" :label="item.fieldName" v-else>
          {{ item.fieldValue }}
        </info-item>
      </div>
    </div>
    <div class="form-wrap" slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <g-title>基本信息</g-title>
        <a-form-model-item label="术语名字" prop="termsName" v-if="false">
          <a-input v-model="formData.termsName" placeholder="请输入术语名字" />
        </a-form-model-item>
        <a-form-model-item label="分类" prop="termsTemplate">
          <a-auto-complete
            placeholder="分类"
            v-model="formData.termsTemplateName"
            @change="templateNameChanged"
          >
            <template slot="dataSource">
              <a-select-option v-for="item in termTemplates" :key="item.id + ''">{{
                item.templateName
              }}</a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>

        <g-title>正文</g-title>
        <div v-for="(item, index) in formData.fields" :key="index" class="field">
          <a-form-model-item
            :label="item.fieldName"
            v-if="item.fieldClassId === 1"
            :prop="item.isRequired ? 'fields[' + index + ']' : ''"
          >
            <a-input v-model="item.fieldValue" placeholder="请输入字段值" />
          </a-form-model-item>
          <a-form-model-item
            :label="item.fieldName"
            v-else-if="item.fieldClassId === 2"
            :prop="item.isRequired ? 'fields[' + index + ']' : ''"
          >
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :action="ossUploadUrl"
              :data="ossUploadData"
              :beforeUpload="beforeUpload"
              :show-upload-list="false"
              @change="(info) => handleChangeUpload(info, index)"
            >
              <img v-if="item.fieldValue" :src="item.fieldValue" class="upload_img" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-model-item>

          <a-form-model-item
            :label="item.fieldName"
            v-else-if="item.fieldClassId === 3"
            :prop="item.isRequired ? 'fields[' + index + ']' : ''"
          >
            <a-input-number v-model="item.fieldValue" placeholder="请输入字段值" />
          </a-form-model-item>
          <a-form-model-item :label="item.fieldName" v-else :prop="item.isRequired ? 'fields[' + index + ']' : ''">
            <a-date-picker v-model="item.fieldValue" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'

const defaultFormData = {
  termsName: '',
  termsTemplateName: '',
  fields: [],
}
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    id: [Number, String],
    productionId: [Number, String],
    curterm: { type: Object, default: {} },
    termTemplates: { type: Array, default: [] },
  },
  components: {},
  watch: {
    show(val) {
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
    },
  },
  data() {
    return {
      headers: {
        'X-Access-Token': Vue.ls.get(ACCESS_TOKEN),
      },
      rules: {},
      labelWidth: '100',
      layout: {
        labelCol: { style: 'width: 100px;', span: 4 },
        wrapperCol: { span: 20 },
      },
      isEdit: false,
      isSaved: false,
      formData: {
        termsName: '',
        termsTemplateName: '',
        fields: [],
      },
      loading: false,
    }
  },
  methods: {
    closed() {
      const self = this
      if (this.isSaved) {
        this.$emit('closed', false)
        this.resetForm()
      } else {
        let newData = JSON.stringify(this.formData)
        let oldData = JSON.stringify(this.oldFormData)
        if (newData != oldData) {
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，确定要退出吗？',
            onOk() {
              self.$emit('closed', false)
              self.resetForm()
            },
          })
        } else {
          self.$emit('closed', false)
          self.resetForm()
        }
      }
    },
    templateNameChanged(value) {
      if (this.isNew) {
        let matched = this.termTemplates.filter((one) => one.id == value)
        if (matched && matched.length > 0) {
          let one = matched[0]
          this.formData.termsName = one.templateName
          this.formData.termsTemplateName = one.templateName
          this.formData.templateId = one.templateId
          this.formData.fields = Object.assign([], one.fields)
          this.$forceUpdate()
        }
      } else {
        let matched = this.termTemplates.filter((one) => one.id == value)
        if (matched && matched.length > 0) {
          let one = matched[0]
          this.formData.termsName = one.templateName
          this.formData.termsTemplateName = one.templateName
          this.formData.templateId = one.templateId
          let fields = Object.assign([], this.formData.fields)
          this.formData.fields = Object.assign([], one.fields)
          for (let key in this.formData.fields) {
            if (fields[key]) {
              this.formData.fields[key] = fields[key];
            }
          }
          this.$forceUpdate()
        }
      }
    },
    resetForm() {
      this.isEdit = false
      this.formData = Object.assign({}, defaultFormData)
    },
    handleEdit() {
      this.isEdit = true
      this.formData = {
        productionId: this.productionId,
        termsId: this.curterm.termsId,
        termsTemplateName: this.curterm.termsTemplate.templateName,
        fields: this.curterm.fields,
        termsName: this.curterm.termsName,
        // 编辑也需要传入术语ID，避免编辑保存后数据丢失
        templateId: this.curterm.termsTemplate.templateId,
      }
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.rules = {
        termsName: [{ required: true, message: '请输入术语名称', trigger: 'blur' }],
      }
      for (let idx = 0; idx < this.curterm.fields.length; idx++) {
        this.rules['fields[' + idx + ']'] = [{ validator: this.requiredField, trigger: 'blur' }]
      }
    },
    handleSave() {
      const that = this
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (that.isNew) {
            that.formData.productionId = this.productionId
            postAction('/terms/add_terms', that.formData).then((res) => {
              if (res.success) {
                that.$message.success('添加成功')
              } else {
                that.$message.error('添加失败')
              }
              that.$emit('closed', false)
              that.resetForm()
            })
          } else {
            putAction('/terms/edit_terms', that.formData).then((res) => {
              if (res.success) {
                that.$message.success('修改成功')
              } else {
                that.$message.error('修改失败')
              }
              that.$emit('closed', false)
              that.resetForm()
            })
          }
        }
      })
    },
    handleChangeUpload(info, index) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.$set(this.formData.fields, index, {
          ...this.formData.fields[index],
          fieldValue: params.filePath,
        })
        this.loading = false
      }
    },
    requiredField(rule, field, callback) {
      if (!field) {
        callback('该字段为必填字段')
      } else {
        if (field.isRequired && !field.fieldValue) {
          callback(field.fieldName + '为必填字段')
        } else {
          callback()
        }
      }
    },
  },
  created() {},
}
</script>

<style lang='less'>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload_img {
  max-width: 200px;
}
</style>