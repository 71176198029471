var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.isNew ? "新建" : _vm.curterm.termsName,
        show: _vm.show,
        isForm: _vm.isNew,
        permission: "production:termtemplate:button:edit",
      },
      on: { edit: _vm.handleEdit, closed: _vm.closed, save: _vm.handleSave },
    },
    [
      _c(
        "div",
        { staticClass: "info-group" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          false
            ? _c(
                "info-item",
                { attrs: { labelWidth: _vm.labelWidth, label: "术语名" } },
                [_vm._v(_vm._s(_vm.curterm.termsName))]
              )
            : _vm._e(),
          _c(
            "info-item",
            { attrs: { labelWidth: _vm.labelWidth, label: "分类" } },
            [
              _vm._v(
                _vm._s(
                  _vm.curterm.termsTemplate
                    ? _vm.curterm.termsTemplate.templateName
                    : ""
                )
              ),
            ]
          ),
          _c("g-title", [_vm._v("正文")]),
          _vm._l(_vm.curterm.fields, function (item) {
            return _c(
              "div",
              { key: item.id },
              [
                item.fieldClassId === 2
                  ? _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: item.fieldName,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "upload_img",
                          attrs: { src: item.fieldValue, alt: "avatar" },
                        }),
                      ]
                    )
                  : _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: item.fieldName,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(item.fieldValue) + "\n      "
                        ),
                      ]
                    ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-wrap", attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c("g-title", [_vm._v("基本信息")]),
              false
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "术语名字", prop: "termsName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入术语名字" },
                        model: {
                          value: _vm.formData.termsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "termsName", $$v)
                          },
                          expression: "formData.termsName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "分类", prop: "termsTemplate" } },
                [
                  _c(
                    "a-auto-complete",
                    {
                      attrs: { placeholder: "分类" },
                      on: { change: _vm.templateNameChanged },
                      model: {
                        value: _vm.formData.termsTemplateName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "termsTemplateName", $$v)
                        },
                        expression: "formData.termsTemplateName",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "dataSource" },
                        _vm._l(_vm.termTemplates, function (item) {
                          return _c("a-select-option", { key: item.id + "" }, [
                            _vm._v(_vm._s(item.templateName)),
                          ])
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("g-title", [_vm._v("正文")]),
              _vm._l(_vm.formData.fields, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "field" },
                  [
                    item.fieldClassId === 1
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: item.fieldName,
                              prop: item.isRequired
                                ? "fields[" + index + "]"
                                : "",
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入字段值" },
                              model: {
                                value: item.fieldValue,
                                callback: function ($$v) {
                                  _vm.$set(item, "fieldValue", $$v)
                                },
                                expression: "item.fieldValue",
                              },
                            }),
                          ],
                          1
                        )
                      : item.fieldClassId === 2
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: item.fieldName,
                              prop: item.isRequired
                                ? "fields[" + index + "]"
                                : "",
                            },
                          },
                          [
                            _c(
                              "a-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  name: "file",
                                  "list-type": "picture-card",
                                  action: _vm.ossUploadUrl,
                                  data: _vm.ossUploadData,
                                  beforeUpload: _vm.beforeUpload,
                                  "show-upload-list": false,
                                },
                                on: {
                                  change: (info) =>
                                    _vm.handleChangeUpload(info, index),
                                },
                              },
                              [
                                item.fieldValue
                                  ? _c("img", {
                                      staticClass: "upload_img",
                                      attrs: {
                                        src: item.fieldValue,
                                        alt: "avatar",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      [
                                        _c("a-icon", {
                                          attrs: {
                                            type: _vm.loading
                                              ? "loading"
                                              : "plus",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "ant-upload-text" },
                                          [_vm._v("Upload")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        )
                      : item.fieldClassId === 3
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: item.fieldName,
                              prop: item.isRequired
                                ? "fields[" + index + "]"
                                : "",
                            },
                          },
                          [
                            _c("a-input-number", {
                              attrs: { placeholder: "请输入字段值" },
                              model: {
                                value: item.fieldValue,
                                callback: function ($$v) {
                                  _vm.$set(item, "fieldValue", $$v)
                                },
                                expression: "item.fieldValue",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: item.fieldName,
                              prop: item.isRequired
                                ? "fields[" + index + "]"
                                : "",
                            },
                          },
                          [
                            _c("a-date-picker", {
                              attrs: {
                                format: "YYYY-MM-DD",
                                valueFormat: "YYYY-MM-DD",
                              },
                              model: {
                                value: item.fieldValue,
                                callback: function ($$v) {
                                  _vm.$set(item, "fieldValue", $$v)
                                },
                                expression: "item.fieldValue",
                              },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }